<template lang="pug">
b-button-group(vertical)
  b-button.plus(variant="primary" size="lg" @click="set_value(+1)"
           v-bind:class="plus_disabled ? 'disabled' : ''")
    icon(name="plus")
  b-form-input(size="lg" v-model="edited_value" maxlength="2"
               v-on:change="value_changed"
               v-bind:class="value_error ? 'error' : ''")
  b-button.minus(variant="primary" size="lg" @click="set_value(-1)"
           v-bind:class="minus_disabled ? 'disabled' : ''")
    icon(name="minus")
</template>

<script>
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/plus'
import 'vue-awesome/icons/minus'

export default {
  name: 'TimePicker',
  components: {
    Icon
  },
  // props: ['type', 'format'],
  props: {
    'value': { type: Number, default: 10 },
    'min': { type: Number, default: 0 },
    'max': { type: Number, default: 59 }
  },

  data () {
    return {
      edited_value: NaN,
      // max: 59,
      plus_disabled: false,
      minus_disabled: false,
      value_error: false
    }
  },

  watch: {
    value (new_value, unused_old_value) {
      console.log(`Value:${new_value}`)
      if (isNaN(new_value)) return
      if (parseInt(this.edited_value, 10) === parseInt(new_value, 10)) return
      this.edited_value = new_value
      if (`${this.edited_value}`.length === 1) this.edited_value = `0${this.edited_value}`
    },
    min (new_min) {
      this.minus_disabled = (parseInt(this.edited_value, 10) <= new_min)
    },
    max (new_max) {
      this.plus_disabled = (parseInt(this.edited_value, 10) >= new_max)
    },
    edited_value: function (new_edited_value) {
      // console.log('Value changed : ' + this.edited_value)
      this.value_error = false
      this.plus_disabled = false
      this.minus_disabled = false
      let value = parseInt(new_edited_value, 10)
      if (isNaN(value)) {
        this.$emit('valuechanged', value)
        this.value_error = true
        this.plus_disabled = true
        this.minus_disabled = true
        return
      }
      if (value > this.max || value < this.min) {
        this.$emit('valuechanged', NaN)
        this.value_error = true
      } else {
        this.$emit('valuechanged', value)
      }
      this.plus_disabled = (value >= this.max)
      this.minus_disabled = (value <= this.min)
    }
  },

  mounted () {
    this.edited_value = (`${this.value}`.length === 1) ? `0${this.value}` : `${this.value}`
  },

  methods: {
    value_changed: function (unused_event) {
      if (this.value_error) return
      if (`${this.edited_value}`.length === 1) this.edited_value = `0${this.edited_value}`
    },
    set_value: function (increase) {
      let value = parseInt(this.edited_value)
      if (value >= this.max && increase >= 1) return
      if (value <= this.min && increase < 0) return
      this.edited_value = `${value + increase}`
      if (`${this.edited_value}`.length === 1) this.edited_value = `0${this.edited_value}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/colors';
@import '../assets/constants';
@import '../assets/mixins';

.btn-group-vertical {
  background-color: '#0275d8';
  border: 1px solid lighten($black_shadow_light, 20);
  border-radius: .15rem;
  font-size: 1.5rem;
  width: 50px;

  input {
    border-bottom: 1px solid transparent;
    border: 0;
    border-radius: 0;
    font-size: 1.5rem;
    padding: .75rem 0;
    text-align: center;
    // width: 3.4em;

    &.error {
      background-color: lighten($red, 20);
      color: $white;
      //width: 3.4em;
    }
  }

  button {
    border-radius: 0;
    &.plus {
      border-top-left-radius: .15em;
      border-top-right-radius: .15em;
    }
    &.minus {
      border-bottom-left-radius: .15em;
      border-bottom-right-radius: .15em;
    }
    .fa-icon {
      height: 1em;
      width: auto;
    }
  }
}

</style>
