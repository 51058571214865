<template lang="pug">
div
  b-modal(ref="modal" modal-class="own_modal" :no-auto-focus="true" :no-enforce-focus="true")
    //- header
    template(slot="modal-header")
      div.content(v-bind:style="{'background-color': event_color}")
        div.inner.pb-3
          div.title {{ title }}
    //- content
    b-form-select(v-model="selected_action" :options="action_form_options"
                  v-bind:style="{'background-color': event_color}")
    icon.form-select(name="sort-down")
    div.zone_edit(v-bind:style="{'background-color': event_color}")
      div.row.pt-4.pb-3
        div.col
          icon(name="hourglass-start")
          span.pl-3 Start at
        div.col
          icon(name="hourglass-end")
          span.pl-3 End at
      div.row.pb-5
        div.col
          time-picker(:value="start_hour" :min="start_hour_min" :max="start_hour_max"
                      @valuechanged="value => { value_changed('start_hour', value) }")
          div.separator :
          time-picker(v-model="start_minute" :min="0" :max="start_minute_max"
                      @valuechanged="value => { value_changed('start_minute', value) }")
        div.col
          time-picker(v-model="end_hour" :min="end_hour_min" :max="23"
                      @valuechanged="value => { value_changed('end_hour', value) }")
          div.separator :
          time-picker(v-model="end_minute" :min="end_minute_min" :max="59"
                      @valuechanged="value => { value_changed('end_minute', value) }")
    b-button.delete(v-if="!new_event" @click="delete_event()"
                    v-bind:class="edited ? 'disabled' : ''" variant="danger" size="lg")
      icon(name="trash")
      span.pl-3 Delete event
    //- footer
    template(slot="modal-footer")
      b-button-group
        b-button.left_radius(@click="hide()"
                 :variant="!submit_enabled ? 'success' : 'danger'" size="lg"
                 class="dismiss") {{ submit_enabled ? 'Cancel' : 'Close' }}
        b-button.right_radius.submit(@click="submit()" size="lg"
                        :variant="submit_enabled ? 'success' : 'warning' "
                        v-bind:class="submit_enabled ? '' : 'disabled' ") Apply
</template>

<script>
import { mapGetters } from 'vuex'
import bus from '@/services/bus'
import { get_random_id, get_element_by_id } from '@/utils'
import CONF from '@/conf'
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/sort-down'
import 'vue-awesome/icons/trash'
import 'vue-awesome/icons/hourglass-start'
import 'vue-awesome/icons/hourglass-end'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import TimePicker from '@/components/time_picker.vue'
import moment from 'moment-timezone'

export default ({
  name: 'Scheduler_event_modal',
  components: {
    Icon,
    ClipLoader,
    TimePicker
  },
  data () {
    return {
      modal: null,
      event: null,
      scheduler: null,
      selected_action: null,
      start_hour: NaN,
      start_minute: NaN,
      end_hour: NaN,
      end_minute: NaN,
      submit_enabled: false,
      data_error: true,
      edited: false,
      new_event: false
    }
  },

  computed: {
    ...mapGetters({
      scheduler_map: 'get_scheduler_map',
      action_map: 'get_action_map'
    }),
    title () {
      if (!this.scheduler) return
      return this.scheduler.name
    },
    action_form_options () {
      if (!this.scheduler) return []
      let options = []
      for (let action of this.action_map) {
        if (action.outputdata !== this.scheduler.data) continue
        if (action.id === this.scheduler.default_action) continue
        options.push({value: action.id, text: action.name})
      }
      return options
    },
    start_hour_min () {
      if (this.scheduler == null) return 0
      return this.scheduler.begin_with_hour
    },
    start_hour_max () {
      if (isNaN(this.end_hour)) return 23
      return this.end_hour
    },
    start_minute_max () {
      if (this.start_hour < this.end_hour) return 59
      if (this.end_minute < 5) return 0
      return this.end_minute - 5
    },
    end_hour_min () {
      if (isNaN(this.start_hour)) return 0
      return this.start_hour
    },
    end_minute_min () {
      if (this.start_hour < this.end_hour) return 0
      return this.start_minute + 5
    },
    event_modal_title () {
      if (!this.event) return
      return `${this.event.start.format('dddd')}`
    },
    event_color () {
      if (!this.event) return
      return this.event.color
    }
  },

  watch: {
    selected_action: function (new_value) {
      if (!this.event) return
      if (this.event.action === new_value) return
      let action = get_element_by_id(this.action_map, this.selected_action)
      this.event.action = action.id
      this.event.color = action.color
      this.edited = true
    }
  },

  mounted () {
    this.modal = this.$refs['modal']
    // Add watchers for enabling submit
    for (let data_prop of ['edited', 'new_event', 'data_error']) {
      this.$watch(data_prop, () => {
        this.submit_enabled = ((this.edited || this.new_event) && !this.data_error)
      })
    }
    // Add watchers for data validation
    for (let data_prop of ['start_hour', 'start_minute', 'end_hour', 'end_minute'])
      this.$watch(data_prop, () => { this.validate_data() })
  },

  methods: {
    show (event, is_new = false) {
      console.log(`Show, is_new:${is_new}, event:${event.id}`)
      this.new_event = is_new
      this.edited = false
      this.validate_data()
      this.scheduler = get_element_by_id(this.scheduler_map, event.scheduler)
      if (is_new) {
        event.id = get_random_id()
        event.action = this.action_form_options[0].value
        let action = get_element_by_id(this.action_map, event.action)
        event.title = action.name
        event.color = action.color
      }
      this.event = event
      this.selected_action = event.action
      this.start_hour = event.start.hour()
      this.start_minute = event.start.minute()
      this.end_hour = event.end.hour()
      this.end_minute = event.end.minute()
      this.modal.show()
    },

    validate_data () {
      this.data_error = false
      for (let entry of [this.start_hour, this.start_minute, this.end_hour, this.end_minute]) {
        this.data_error = isNaN(entry)
        if (this.data_error) break
      }
    },

    hide () { this.modal.hide() },

    value_changed (item, value) {
      if (parseInt(this.$data[item], 10) === parseInt(value, 10)) return
      this.edited = true
      this.$data[item] = value
    },

    submit (delete_event = false) {
      if (!this.event || !this.submit_enabled) return
      let event_day = this.event.start.weekday() === 6 ? 0 : this.event.start.weekday() + 1
      let start_tz = moment().hours(this.start_hour).minutes(this.start_minute)
                             .day(event_day).tz('UTC')
      let end_tz = moment().hours(this.end_hour).minutes(this.end_minute)
                           .day(event_day).tz('UTC')
      let submit = this.$http.post(CONF.SCHEDULER_EVENT_URL,
        {
          id: this.event.id,
          scheduler: this.scheduler.id,
          action: this.event.action,
          day_of_week: start_tz.day(),
          start_hour: start_tz.hour(),
          end_hour: end_tz.hours(),
          start_minute: start_tz.minutes(),
          end_minute: end_tz.minutes(),
          delete: delete_event
        },
        CONF.REQUEST_OPTIONS
      )
      submit.then(
        unused_response => {
          this.hide()
          this.$store.commit('reset_scheduler_event_map', this.scheduler.id)
          this.$socket.send('scheduler_event_map', this.scheduler.id)
          bus.$emit('route/loading', null)
        },
        response => {
          console.error(`Error POST request: ${response.status} ${response.body.text}`)
          this.$toaster['error'](response.body.text)
        }
      )
    },

    delete_event () {
      if (this.new_event || this.edited) return
      this.submit_enabled = true
      this.submit(true)
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../assets/colors';
@import '../assets/constants';
@import '../assets/mixins';
@import '~fullcalendar/dist/fullcalendar.css';

.own_modal {
  color: $white;

  .modal-header {
    .content {
      margin: 0;
      padding: 0;
    }
    .title {
      color: $white !important;
    }
  }

  select {
    border: 0;
    border-radius: 0;
    color: inherit !important;
    font-size: 1.5rem;
    height: auto !important;
    padding: .5rem;
  }

  .delete {
    width: 100% !important;
  }

  .row {
    background-color: $white_shadow_light;
    margin: 0;
  }

  .col {
    font-size: 2rem;
    text-align: center;
  }

  .separator {
    color: $black;
    display: inline-block;
    font-size: 1.5rem;
    padding: 0 1rem;
  }

  .right {
    text-align: right;
  }
}


</style>
