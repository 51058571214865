<template lang="pug">
div.root_container
  vue-headful(title="Ownmesh schedulers")
  //- SCHEDULER EVENT MODAL
  scheduler-event-modal(ref='scheduler_event_modal')
  //- SCHEDULER ADD & SETTINGS MODAL CONTENT
  b-modal(ref="scheduler_config_modal" id="scm" modal-class="own_modal scheduler_config_modal"
          :no-auto-focus="true" :no-enforce-focus="true")
    //- header
    template(slot="modal-header")
      div.content(v-bind:style="{'background-color': scheduler_color}")
        div.inner.pb-3
          div.title {{ selected_scheduler_name  }}
    //- content
    form
      div(v-bind:style="{'background-color': scheduler_color}")
        b-form-select(v-model="edited_scheduler_type" :options="scheduler_type_options")
        icon.form-select(name="sort-down")
      div(v-bind:style="{'background-color': scheduler_color}")
        b-form-select(v-model="edited_scheduler_begin" :options="scheduler_begin_options")
        icon.form-select(name="sort-down")
      div.action(v-bind:style="{'background-color': scheduler_color}")
        div Default action
        b-form-select(v-model="edited_default_action" :options="default_action_options")
        icon.form-select(name="sort-down")
    //-b-button(@click="delete_scheduler()" size="lg" class="danger")
      icon
      {{ scheduler_submit_enabled ? 'Cancel' : 'Close' }}
    //- footer
    template(slot="modal-footer")
      b-button-group
        b-button.left_radius(@click="hide_scheduler_config_modal()"
                 :variant="!scheduler_submit_enabled ? 'success' : 'danger'" size="lg"
                 class="dismiss") {{ scheduler_submit_enabled ? 'Cancel' : 'Close' }}
        b-button.submit.right_radius(@click="submit_scheduler()" size="lg"
                        :variant="scheduler_submit_enabled ? 'success' : 'warning' "
                        v-bind:class="scheduler_submit_enabled ? '' : 'disabled' ") Apply

  div.row.scheduler_form
    div.col
      b-form-select(v-model="scheduler_pk" :options="scheduler_form_options"
                    v-bind:style="{'background-color': scheduler_color}")
      icon.form-select(name="sort-down")
  //- div(v-bind:style="{'background-color': scheduler_color_shadow}" v-show="scheduler")
  div(v-bind:style="{'background-color': scheduler_color_shadow}")
    full-calendar(ref="calendar" :events="events" :config="config"
                  @event-selected="on_event_selected" @event-created="on_event_created"
                  @day-click="on_event_created")
</template>

<script>
import CONF from '@/conf'
import 'vue-awesome/icons/plug'
import { mapGetters } from 'vuex'
import bus from '@/services/bus'
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/sort-down'
import { FullCalendar } from 'vue-full-calendar'
// var moment = require('moment-timezone')
import moment from 'moment-timezone'
// import moment from 'moment'

import { get_element_by_id, hex_to_rgb } from '@/utils'
import Scheduler_event_modal from '@/components/Scheduler_event_modal.vue'
import '@/assets/fullcalendar.scss'

export default ({
  name: 'Scheduler_landing',
  components: {
    'full-calendar': FullCalendar,
    'icon': Icon,
    'scheduler-event-modal': Scheduler_event_modal
  },
  data () {
    return {
      landing: true,
      calendar: null,
      loading: true,
      scheduler_event_modal: null,
      scheduler_config_modal: null,
      events: [],
      config: {
        defaultView: 'agendaWeek',
        slotLabelFormat: 'H[h]',
        allDaySlot: false,
        columnFormat: 'dddd',
        timeFormat: 'H:mm',
        editable: false,
        header: {left: '', center: '', right: ''},
        contentHeight: 'auto',
        firstDay: 1,
        minTime: '00:00:00',
        themeSystem: 'bootstrap3'
      },
      scheduler: null,
      scheduler_pk: null,
      edited_scheduler_begin: 0,
      scheduler_begin_options: [],
      edited_scheduler_type: null,
      scheduler_type_options: [
        {value: 'WE', text: 'Weekly scheduler'},
        {value: 'OD', text: 'Open days/ Weekend scheduler'},
        {value: 'DY', text: 'Daily scheduler'}
      ],
      edited_default_action: null,
      default_action_options: [],
      on_scheduler_config: false
    }
  },

  computed: {
    ...mapGetters({
      scheduler_map: 'get_scheduler_map',
      scheduler_event_map: 'get_scheduler_event_map',
      action_map: 'get_action_map'
    }),

    scheduler_form_options () {
      let options = []
      for (let scheduler of this.scheduler_map) {
        options.push({value: scheduler.id, text: scheduler.name})
      }
      return options
    },

    selected_scheduler_name () {
      if (!this.scheduler || this.new_scheduler) return 'New scheduler'
      return this.scheduler.name
    },

    eventSources () {
      return [{ events (start, end, timezone, callback) { callback(this.events) } }]
    },

    scheduler_color () {
      let default_action
      if (!this.edited_default_action) {
        if (!this.scheduler) return 'transparent'
        if (!this.scheduler.default_action) return 'transparent'
        default_action = get_element_by_id(this.action_map, this.scheduler.default_action)
      } else {
        default_action = get_element_by_id(this.action_map, this.edited_default_action)
      }
      if (!default_action) return 'transparent'
      return default_action.color
    },

    scheduler_color_shadow () {
      return hex_to_rgb(this.scheduler_color, 0.5)
    },

    scheduler_submit_enabled () {
      if (!this.scheduler) return false
      let enabled = false
      if (this.edited_scheduler_type !== this.scheduler.type) enabled = true
      if (this.edited_default_action !== this.scheduler.default_action) enabled = true
      if (this.edited_scheduler_begin !== this.scheduler.begin_with_hour) enabled = true
      return enabled
    }
  },

  watch: {
    scheduler_pk: function (new_pk, old_pk) {
      if (old_pk) bus.$off(`update/scheduler_event_map/${old_pk}`)
      if (!new_pk) return
      this.scheduler = get_element_by_id(this.scheduler_map, new_pk)
      if (!this.scheduler) return
      bus.$on(`update/scheduler_event_map/${this.scheduler_pk}`, () => {
        this.refresh_calendar()
        bus.$emit('route/ready', null)
      })
      let event_map = get_element_by_id(this.scheduler_event_map, this.scheduler_pk)
      if (!event_map) {
        this.$socket.send('scheduler_event_map', this.scheduler_pk)
        return
      }
      this.refresh_calendar()
      bus.$emit('route/ready', null)
    }
  },

  mounted () {
    for (let count = 0; count < 9; count++) {
      this.scheduler_begin_options.push({value: count, text: `Begin at 0${count}h`})
    }
    this.landing = this.$route.params.id === 'landing'
    if (this.$refs.hasOwnProperty('calendar')) this.calendar = this.$refs.calendar
    this.scheduler_event_modal = this.$refs['scheduler_event_modal']
    this.scheduler_config_modal = this.$refs['scheduler_config_modal']
    if (this.scheduler_map.length) this.refresh_scheduler()
    else {
      setTimeout(() => {
        this.calendar.fireMethod('option', this.config)
        this.calendar.$emit('refetch-events')
      }, 100)
    }
    bus.$on('socket/state', (response) => {
      if (!response || this.scheduler_map.length) return
      let already_fetched = ''
      if (this.data_map) {
        if (this.data_map.length) already_fetched += 'data,'
      }
      this.$socket.send('scheduler_map', JSON.stringify({already_fetched: already_fetched}))
    })
    this.$socket.connect()
    bus.$on('update/scheduler_map', () => { this.refresh_scheduler() })
    bus.$on('show_scheduler_config_modal', () => {
      this.show_scheduler_config_modal(this.scheduler_pk)
    })
    bus.$on('show_scheduler_add_modal', () => {
      this.show_scheduler_config_modal()
    })
  },

  beforeDestroy: function () {
    bus.$off('socket/state')
    bus.$off('update/scheduler_map')
    bus.$off('update/show_scheduler_config_modal')
    bus.$off('update/show_scheduler_add_modal')
    this.events = []
  },

  methods: {
    refresh_scheduler () {
      if (this.scheduler_pk) bus.$off(`update/scheduler_event_map/${this.scheduler_pk}`)
      if (this.landing) this.scheduler_pk = this.scheduler_pk || this.scheduler_map[0].id
      else this.scheduler_pk = parseInt(this.$route.params.id, 10)
      bus.$on(`update/scheduler_event_map/${this.scheduler_pk}`, () => {
        this.refresh_calendar()
        bus.$emit('route/ready', null)
      })
      this.refresh_calendar()
      bus.$emit('route/ready', null)
    },

    refresh_calendar () {
      if (!this.calendar) return
      if (!this.scheduler_pk) return
      this.scheduler = get_element_by_id(this.scheduler_map, this.scheduler_pk)
      this.config.minTime = `0${this.scheduler.begin_with_hour}:00:00`
      this.calendar.fireMethod('option', 'minTime', this.config.minTime)
      this.events = []
      let event_map = get_element_by_id(this.scheduler_event_map,
                                        this.scheduler_pk)
      if (!event_map) return
      if (!event_map.entries.length) return
      for (let store_event of event_map.entries) {
        if (store_event.scheduler !== this.scheduler_pk) continue
        // week begin with monday as 0 -> doing conversion
        // console.log(moment().day())
        let today = moment().day() ? moment().day() : 7
        let event_day = parseInt(store_event.day_of_week, 10)
        let day_delta = event_day - today
        console.log(`today: ${today} - event_day: ${event_day}`)
        if (!event_day) day_delta += 7
        let action = get_element_by_id(this.action_map, store_event.action)
        if (action == undefined) continue
        let event = {
          id: store_event.id, title: action.shortname, action: action.id, color: action.color,
          start: moment().tz('UTC').hours(store_event.start_hour)
                         .minutes(store_event.start_minute)
                         .tz(moment.tz.guess()),
          end:  moment().tz('UTC').hours(store_event.end_hour)
                        .minutes(store_event.end_minute)
                        .tz(moment.tz.guess())
        }
        for (let event_date of [event.start, event.end]) {
          console.log(`event_date before: ${event_date}`)
          if (day_delta < 0)
            event_date = event_date.subtract(Math.abs(day_delta), 'days')
          else event_date = event_date.add(day_delta, 'days')
          console.log(`event_date after: ${event_date}`)
        }
        this.events.push(event)
      }
      setTimeout(() => { this.calendar.$emit('rerender-events') }, 200)
    },

    on_event_selected (event) {
      event.scheduler = this.scheduler_pk
      this.scheduler_event_modal.show(event)
    },

    on_event_created (event) {
      event.scheduler = this.scheduler_pk
      if (event.start === undefined) {
        event.start = moment().hours(0).minutes(0)
        event.end = moment().hours(1).minutes(0)
      }
      this.scheduler_event_modal.show(event, true)
    },

    show_scheduler_config_modal () {
      if (!this.scheduler) return
      this.on_scheduler_config = true
      let set_action_options = () => {
        if (!this.on_scheduler_config) return
        this.default_action_options = []
        for (let action of this.action_map) {
          if (action.outputdata !== this.scheduler.data) continue
          this.default_action_options.push({value: action.id,
                                            text: action.shortname})
        }
        this.edited_default_action = this.scheduler.default_action
      }
      this.edited_scheduler_begin = this.scheduler.begin_with_hour
      this.edited_scheduler_type = this.scheduler.type
      this.default_action = this.scheduler.default_action.id
      if (!this.action_landing_state) this.$socket.send('action_map')
      else set_action_options()
      bus.$on('update/action_map', () => {
        set_action_options()
      })
      this.scheduler_config_modal.show()
    },

    hide_scheduler_config_modal () {
      this.default_action_options = []
      this.scheduler_config_modal.hide()
      this.edited_default_action = null
      this.edited_scheduler_begin = null
      this.edited_scheduler_type = null
      this.new_scheduler = false
      this.on_scheduler_config = false
    },

    submit_scheduler () {
      if (!this.scheduler_submit_enabled) return
      let submit = this.$http.post(CONF.SCHEDULER_CONFIG_URL,
        {
          id: this.scheduler.id,
          data: this.scheduler.data,
          default_action: this.edited_default_action,
          begin_with_hour: this.edited_scheduler_begin,
          type: this.edited_scheduler_type
        },
        CONF.REQUEST_OPTIONS
      )
      submit.then(
        unused_response => {
          this.hide_scheduler_config_modal()
          this.$socket.send('scheduler_map')
          bus.$emit('route/loading', null)
        },
        response => {
          console.log(`Error POST request: ${response.status} ${response.body.text}`)
          this.$toaster['error'](response.body.text)
        }
      )
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../assets/colors';
@import '../assets/constants';
@import '../assets/mixins';

.scheduler_form {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  .col {
    color: lighten($text_color3, 10);
    padding: 0;
  }
  .custom-select {
    border: 0;
    border-radius: 0;
    color: $white;
    font-size: 1.5rem;
    height: 100% !important;
    min-width: 180px;
    padding-bottom: .6rem;
  }
  .form-select {
    color: $white !important;
  }
}

.scheduler_config_modal {
  color: $white !important;
  .modal-header {
    .title {
      color: $white !important;
    }
  }
  .modal-body {
    form {
      > div {
        align-items: center;
        display: flex;
        overflow: hidden;
        padding: 1rem .5rem;
        position: relative;

        > span,
        > input,
        > select,
        > div {
          font-size: 1.5rem;
          height: 3rem;
          line-height: 3rem;
        }

        > div {
          min-width: 30%;
          padding-left: .9rem;
        }

        select {
          cursor: pointer;
          background-color: transparent;
          border: 0;
          border-radius: 0;
          color: inherit !important;
          font-size: 1.5rem;
          height: auto !important;
          padding: .5rem;
        }

        .fa-icon.form-select {
          height: 16px;
          top: 1.2rem;
        }
      }
    }
  }
}

</style>
